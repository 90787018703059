import posthog from 'posthog-js';
import { useEffect, useState } from 'react';
import { useTimeoutFn } from 'react-use';

import { POSTHOG_EVENTS } from './events';
import GetActionableInsightsModal from './get-actionable-insights-modal';

const GetActionableInsightModalAd = () => {
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    const handleMouseLeave = (event: MouseEvent) => {
      const triggerModal = (event: MouseEvent) => event.clientY < 10;
      if (triggerModal(event)) {
        handleShowModal();
      }
    };
    document.addEventListener('mouseleave', handleMouseLeave);
    return () => {
      document.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  const handleShowModal = () => {
    const value = window.sessionStorage.getItem(
      'hasShownGetActionableInsightsModal',
    );
    if (value === 'true') {
      return;
    }
    setShowModal(true);
    posthog.capture(POSTHOG_EVENTS.INSIGHTS_OPENED);
    window.sessionStorage.setItem('hasShownGetActionableInsightsModal', 'true');
  };

  useTimeoutFn(() => {
    handleShowModal();
  }, 30_000);

  const handleCloseModal = () => {
    window.sessionStorage.setItem('hasShownGetActionableInsightsModal', 'true');
    setShowModal(false);
  };

  return (
    <GetActionableInsightsModal open={showModal} onClose={handleCloseModal} />
  );
};

export default GetActionableInsightModalAd;
