import Logo from 'assets/icons/earlynode-logo.svg';
import { NewsletterHorizontal } from 'components/newsletter/newsletter-horizontal-component';
import { Typography } from 'components/typography/typography-component';
import Link from 'next/link';

const navigation = {
  company: [
    { name: 'Imprint', href: '/imprint' },
    { name: 'Privacy', href: '/privacy' },
  ],
  portfolio: [
    { name: 'Releaselog.io', href: '/releaselog', target: '_blank' },
    {
      name: 'React Squad',
      href: '/react-squad',
      target: '_blank',
    },
    {
      name: 'EarlyNode Ventures',
      href: '/ventures',
    },
  ],
  topic: [
    { name: 'AI & Prompt Engineering', href: '/prompt-engineering' },
    { name: 'Product Marketing', href: '/product-marketing' },
    { name: 'Product Management', href: '/product-management' },
    { name: 'Product-Led Growth', href: '/product-led-growth' },
    { name: 'Marketing', href: '/marketing' },
    { name: 'HR & Recruiting', href: '/hr-and-recruiting' },
    { name: 'VC & Fundraising', href: '/vc-fundraising' },
  ],
  resources: [
    { name: 'Newsletters', href: '/newsletters' },
    { name: 'Glossary', href: '/glossary' },
    { name: 'Alternatives', href: '/alternatives' },
    { name: 'Reviews', href: '/reviews' },
    { name: 'Profiles', href: '/profiles' },
    { name: 'Books', href: '/books' },
    { name: 'Podcasts', href: '/podcasts' },
    { name: 'Quotes', href: '/quotes' },
    { name: 'Other', href: '/other' },
  ],
};

export function Footer() {
  return (
    <footer aria-labelledby="footer-heading" className="bg-[#192733]">
      <NewsletterHorizontal
        variant="fullwidth"
        title={
          <p className="font-normal">
            Get the <span className="font-bold">5-minute newsletter</span> and
            save 100+ hours of research per report.
          </p>
        }
        placeholderText="Your email address"
        buttonText="Join Free"
      />
      <div className="px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        <div className="flex justify-center pb-12">
          <Logo />
        </div>
        <div className="container mx-auto flex flex-col justify-around gap-12 space-x-6 md:flex-row">
          <div className="ml-6 md:mt-0">
            <Typography variant="h3" color="white">
              Company
            </Typography>
            <ul className="mt-4 space-y-4">
              {navigation.company.map(item => (
                <li key={item.name}>
                  <Link
                    href={item.href}
                    className="text-base text-gray-300 hover:text-white"
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div className="ml-6 md:mt-0">
            <Typography variant="h3" color="white">
              Portfolio
            </Typography>
            <ul className="mt-4 space-y-4">
              {navigation.portfolio.map(item => (
                <li key={item.name}>
                  <a
                    href={item.href}
                    className="text-base text-gray-300  hover:text-white"
                    target={item.target}
                  >
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="ml-6 md:mt-0">
            <Typography variant="h3" color="white">
              Topic
            </Typography>
            <ul className="mt-4 space-y-4 sm:hidden md:block">
              {navigation.topic.slice(0, 5).map(item => (
                <li key={item.name}>
                  <Link
                    href={item.href}
                    className="text-base text-gray-300  hover:text-white"
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
            <ul className="mt-4 space-y-4 md:hidden">
              {navigation.topic.slice(5).map(item => (
                <li key={item.name}>
                  <Link
                    href={item.href}
                    className="text-base text-gray-300  hover:text-white"
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="ml-6 hidden md:mt-0 md:block">
            <Typography
              variant="h3"
              className="h-10"
              color="white"
            ></Typography>
            <ul className="mt-4 space-y-4">
              {navigation.topic.slice(4).map(item => (
                <li key={item.name}>
                  <Link
                    href={item.href}
                    className="text-base text-gray-300  hover:text-white"
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="ml-6 md:mt-0">
            <Typography variant="h3" color="white">
              Resources
            </Typography>
            <ul className="mt-4 space-y-4 sm:hidden md:block">
              {navigation.resources.slice(0, 4).map(item => (
                <li key={item.name}>
                  <Link
                    href={item.href}
                    className="text-base text-gray-300  hover:text-white"
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
            <ul className="mt-4 space-y-4 md:hidden">
              {navigation.resources.slice(4).map(item => (
                <li key={item.name}>
                  <Link
                    href={item.href}
                    className="text-base text-gray-300  hover:text-white"
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="ml-6 hidden md:mt-0 md:block">
            <Typography
              variant="h3"
              className="h-10"
              color="white"
            ></Typography>
            <ul className="mt-4 space-y-4">
              {navigation.resources.slice(4).map(item => (
                <li key={item.name}>
                  <Link
                    href={item.href}
                    className="text-base text-gray-300  hover:text-white"
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="mt-6 pt-6">
          <p className="text-center text-base text-white">
            &copy; Copyright {new Date().getFullYear()} - EarlyNode GmbH
          </p>
        </div>
      </div>
    </footer>
  );
}
