import { Footer } from 'components/footer/footer-component';
import GetActionableInsightModalAd from 'components/marketing/get-actionable-insights-modal-ad';
import { NavBar } from 'components/navbar/navbar-component';
import TermlyPreference from 'features/termly/termly-preference-link';
import { ReactNode } from 'react';

const LayoutComponent = ({ children }: { children?: ReactNode }) => {
  return (
    <main>
      <NavBar />
      {children}
      <Footer />
      <GetActionableInsightModalAd />
      <TermlyPreference />
    </main>
  );
};

export default LayoutComponent;
