import { Dialog, Transition } from '@headlessui/react';
import { XCircleIcon } from '@heroicons/react/20/solid';
import axios, { AxiosError } from 'axios';
import { Button } from 'components/button/button-component';
import {
  HandleInitialSubmit,
  InitialFormProps,
} from 'components/newsletter/types';
import { Typography } from 'components/typography/typography-component';
import { Formik } from 'formik';
import { oswald } from 'lib/font/font-config';
import Image from 'next/image';
import posthog from 'posthog-js';
import { Fragment, useEffect, useState } from 'react';

import MailIcon from '../../assets/icons/mail.svg';
import { POSTHOG_EVENTS } from './events';
import { ModalProps } from './types';

export default function GetActionableInsightsModal({
  open,
  onClose,
}: ModalProps) {
  const [error, setError] = useState<string>('');
  const [submitted, setSubmitted] = useState<boolean>(false);

  const handleSubmit: HandleInitialSubmit = async (
    values,
    { setSubmitting, resetForm },
  ) => {
    setSubmitting(true);
    try {
      await axios.post('/api/beehiiv/subscribe', {
        email: values.email,
      });
      setSubmitted(true);
      resetForm();
      posthog.capture(POSTHOG_EVENTS.INSIGHTS_SUBSCRIBED);
    } catch (error) {
      if (error instanceof AxiosError) {
        setError(error.message);
      }
    } finally {
      setSubmitting(false);
    }
  };

  const initialValue: InitialFormProps = {
    email: '',
  };

  useEffect(() => {
    let errorTimeout: NodeJS.Timeout;
    if (error) {
      errorTimeout = setTimeout(() => {
        setError('');
      }, 2000);
    }

    return () => {
      clearTimeout(errorTimeout);
    };
  }, [error]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[100] hidden md:block"
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-white bg-opacity-70 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto drop-shadow-2xl">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-3xl bg-white px-4 pb-8 pt-16 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:px-6 sm:pb-10 sm:pt-6">
                <div className="absolute right-0 top-0  pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="focus:ring-indigo-500 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                    onClick={() => onClose(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div>
                  <div className="mt-2 flex flex-row justify-center  ">
                    <div>
                      <Typography
                        className={`${oswald.className} items-center px-6  text-2xl font-bold leading-[47px] sm:text-[32px]	`}
                        variant="h2"
                      >
                        Get actionable insights from SaaS veterans to grow your
                        MRR.
                      </Typography>
                    </div>
                  </div>

                  <div className="relative h-[407px] w-full">
                    <Image
                      src="/assets/insights.svg"
                      alt="Get actionable insights"
                      width={500}
                      height={500}
                      className="absolute -top-8 -z-10 h-full w-full object-contain"
                    />
                  </div>
                  <div className=" -mt-6 px-6">
                    <Typography
                      variant="lead"
                      className="my-4 text-[14px] font-extrabold"
                    >
                      Join hundreds of SaaS founders:
                    </Typography>
                    {submitted ? (
                      <div className="flex-2 rounded-xl  bg-primary shadow-[0px_0px_4px_3px_rgba(0,0,0,0.03)] lg:pl-4">
                        <Typography
                          variant="lead"
                          className="my-2 mb-4 py-4 text-center text-sm  font-extrabold text-white"
                        >
                          Thank you! Your submission has been received!
                        </Typography>
                      </div>
                    ) : (
                      <Formik
                        initialValues={initialValue}
                        onSubmit={handleSubmit}
                      >
                        {({
                          values,
                          handleChange,
                          isSubmitting,
                          handleSubmit,
                        }) => (
                          <form
                            onSubmit={handleSubmit}
                            className="flex-2 rounded-xl shadow-[0px_0px_4px_3px_rgba(0,0,0,0.03)] lg:pl-4"
                          >
                            <div className="sm:flex-end m-0 w-auto rounded-xl border-8 border-white sm:relative sm:flex sm:items-center sm:justify-between sm:border-none sm:py-0.5 sm:pr-2.5">
                              <div className="flex w-full items-center">
                                <div className="pointer-events-none pl-4 sm:mt-0 sm:pl-4 lg:pl-0">
                                  <MailIcon />
                                </div>
                                <input
                                  type="email"
                                  id="email-address"
                                  required
                                  aria-label="Email address"
                                  placeholder="Your email address"
                                  value={values.email}
                                  onChange={handleChange}
                                  name="email"
                                  className="peer relative mx-2 overflow-x-auto truncate rounded-xl border-none px-2 py-3 text-base placeholder:text-slate-400 focus:border-transparent focus:outline-none focus:ring-0 sm:w-full"
                                />
                              </div>

                              <Button
                                size="md"
                                type="submit"
                                fullwidth
                                disabled={isSubmitting}
                                className="mx-3 my-2 h-[40px] w-11/12 rounded-xl sm:z-10 sm:mx-0 sm:w-auto sm:flex-none"
                              >
                                {isSubmitting ? 'Subscribing...' : 'Subscribe'}
                              </Button>
                            </div>
                            {!error && (
                              <Typography
                                variant="paragraph"
                                className="mb-4 mt-2 w-full text-sm text-red-300"
                              >
                                {error}
                              </Typography>
                            )}
                          </form>
                        )}
                      </Formik>
                    )}
                    <Typography variant="lead" className="my-2 mb-4 text-sm">
                      5-Minute Read. Every Tuesday. For Free.
                    </Typography>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
