import { Popover, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Button } from 'components/button/button-component';
import { Logo } from 'components/logo/logo-component';
import Link from 'next/link';
import { Fragment, useState } from 'react';
import { BsSendPlus } from 'react-icons/bs';

const podcasts = [
  {
    name: 'Inside the Product Marketing Mind',
    href: 'https://insidepmm.earlynode.com/',
  },
  {
    name: 'The Bootstrapped SaaS Operator',
    href: 'https://bootstrapped-saas.earlynode.com/',
  },
  {
    name: 'The Venture-Scale SaaS Operator',
    href: 'https://vc-saas.earlynode.com/',
  },
];

const products = [
  {
    name: 'ReactSquad.io - The React Agency for Startups',
    href: 'https://earlynode.com/react-squad',
  },
  {
    name: 'Releaselog.io - The Changelog Tool for SaaS',
    href: 'https://earlynode.com/releaselog',
  },
];

function MobileNavIcon({ open }: { open?: boolean }) {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
    >
      <path
        d="M0 1H14M0 7H14M0 13H14"
        className={classNames(
          'origin-center transition',
          open && 'scale-90 opacity-0',
        )}
      />
      <path
        d="M2 2L12 12M12 2L2 12"
        className={classNames(
          'origin-center transition',
          !open && 'scale-90 opacity-0',
        )}
      />
    </svg>
  );
}

function MobileNavigation() {
  return (
    <Popover>
      <Popover.Button
        className="relative z-10 flex h-8 w-8 items-center justify-center [&:not(:focus-visible)]:focus:outline-none"
        aria-label="Toggle"
      >
        {({ open }) => <MobileNavIcon open={open} />}
      </Popover.Button>
      <Transition.Root>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            as="div"
            className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 px-2 text-lg tracking-tight text-slate-900 shadow-xl"
          >
            <ul>
              <li>
                <div
                  className="mx-2 mt-2 text-[18px] font-bold lg:text-left"
                  style={{ color: '#2E3CFF' }}
                >
                  Products
                </div>
              </li>
              {products.map(product => (
                <li key={product.name}>
                  <Link href={product.href}>
                    <div className="mx-2 mt-4 text-[18px] text-gray-600 lg:text-left">
                      {product.name}
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
            <ul className="my-4">
              <li>
                <div
                  className="mx-2 mt-2 text-[18px] font-bold lg:text-left"
                  style={{ color: '#2E3CFF' }}
                >
                  Podcasts
                </div>
              </li>
              {podcasts.map(podcast => (
                <li key={podcast.name}>
                  <Link href={podcast.href}>
                    <div className="mx-2 mt-4 text-[18px] text-gray-600 lg:text-left">
                      {podcast.name}
                    </div>
                  </Link>
                </li>
              ))}
            </ul>

            <Link href="/subscribe" className="p-6">
              <Button
                className="mt-6 flex w-full"
                size="lg"
                variant="filled"
                color="blue"
              >
                <BsSendPlus className="mx-2 text-white" />
                Subscribe
              </Button>
            </Link>
          </Popover.Panel>
        </Transition.Child>
      </Transition.Root>
    </Popover>
  );
}

export function NavBar() {
  const [hovering, setHovering] = useState(false);
  const [product, setProduct] = useState(false);
  const hoverHandler = () => {
    setHovering(true);
  };
  return (
    <header className="bg-white px-4 py-4 shadow-[0_6px_12px_rgba(234,240,246,1)]">
      <div className="mx-auto px-2 md:container sm:w-full md:px-0">
        <nav className="relative z-50 flex justify-between">
          <div className="mt-2 flex items-center md:gap-x-12">
            <Link href="/" aria-label="Home">
              <Logo />
            </Link>
          </div>
          <div className="flex items-center gap-x-10 md:gap-x-12">
            <div
              className="hidden flex-row items-center lg:flex"
              onMouseLeave={() => setProduct(false)}
              onMouseEnter={() => setProduct(true)}
            >
              <Popover className="relative">
                <Popover.Button
                  className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 outline-none"
                  onMouseEnter={() => setProduct(true)}
                >
                  <span className="text-[18px] font-bold">Products</span>
                </Popover.Button>

                <Transition
                  as={Fragment}
                  show={product}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute left-0 z-10 -ml-4  mt-6 flex w-screen max-w-min">
                    <div className="w-max shrink rounded-xl bg-white p-4 text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                      {products.map(item => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="block p-2 py-2 hover:text-blue-700"
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </Popover.Panel>
                </Transition>
              </Popover>
            </div>
            <div
              className="hidden flex-row items-center lg:flex"
              onMouseLeave={() => setHovering(false)}
              onMouseEnter={() => setHovering(true)}
            >
              <Popover className="relative">
                <Popover.Button
                  className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 outline-none"
                  onMouseEnter={hoverHandler}
                >
                  <span className="text-[18px] font-bold">Podcasts</span>
                </Popover.Button>

                <Transition
                  as={Fragment}
                  show={hovering}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute left-0 z-10 -ml-4 mt-6 flex w-screen max-w-min">
                    <div className="w-max shrink rounded-xl bg-white p-4 text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                      {podcasts.map(item => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="block p-2 py-2 hover:text-blue-700 "
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </Popover.Panel>
                </Transition>
              </Popover>
            </div>
            <div className="hidden flex-row items-center lg:flex">
              <Link href="/subscribe">
                <Button
                  className="mx-2"
                  size="lg"
                  variant="filled"
                  color="blue"
                >
                  Subscribe
                </Button>
              </Link>
            </div>
            <div className="-mr-1 lg:hidden">
              <MobileNavigation />
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}
