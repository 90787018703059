import EarlyNodeIconSvg from 'components/logo/earlynode-icon';
import React from 'react';

const TermlyPreference = () => {
  return (
    <button
      type="button"
      className="termly-display-preferences  fixed bottom-2 left-2 flex  h-[56px] w-[56px] items-center justify-center rounded-full bg-primary p-2 shadow-lg"
    >
      <EarlyNodeIconSvg />
    </button>
  );
};

export default TermlyPreference;
