import { Variant } from './types';

export const backgroundColors = {
  blue: 'bg-primary',
};

export const newsletters = {
  defaultProps: {
    variant: 'rounded' as Variant,
    color: 'blue' as keyof typeof backgroundColors,
    fullwidth: false,
    title: 'Join 2M+ professionals getting the Hustle daily news brief',
    placeholderText: 'Your email address',
    buttonText: 'Join Free',
    className: '',
  },
  styles: {
    variants: {
      rounded: 'w-full md:container mx-auto rounded-3xl',
      fullwidth: 'w-full',
    },
    baseStyles: 'px-4',
    colors: backgroundColors,
  },
};
