import MailIcon from 'assets/icons/mail.svg';
import axios from 'axios';
import { Button } from 'components/button/button-component';
import { Typography } from 'components/typography/typography-component';
import { Formik } from 'formik';
import { getErrorMessage } from 'lib/utils/is-error';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { backgroundColors, newsletters } from './newsletter-helpers';
import NewsletterModal from './newsletter-modal';
import {
  HandleInitialSubmit,
  InitialFormProps,
  NewsletterProps,
} from './types';

export const NewsletterHorizontal = ({
  variant,
  color,
  title,
  placeholderText,
  buttonText,
  className,
  ...props
}: NewsletterProps) => {
  const { defaultProps, styles } = newsletters;
  const { baseStyles, variants } = styles;
  variant = variant ?? defaultProps.variant;
  title = title ?? defaultProps.title;
  placeholderText = placeholderText ?? defaultProps.placeholderText;
  buttonText = buttonText ?? defaultProps.buttonText;
  className = className ?? defaultProps.className;
  color = color ?? defaultProps.color;
  const bgColor = backgroundColors[color];

  const classes = twMerge(baseStyles, variants[variant], bgColor, className);

  const [modalId, setModalId] = useState<number | null>(null);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    let errorTimeout: NodeJS.Timeout;
    if (error) {
      errorTimeout = setTimeout(() => {
        setError('');
      }, 2000);
    }

    return () => {
      clearTimeout(errorTimeout);
    };
  }, [error]);

  const initialValue: InitialFormProps = {
    email: '',
  };

  const handleSubmit: HandleInitialSubmit = async (
    values,
    { setSubmitting, resetForm },
  ) => {
    setSubmitting(true);
    try {
      const { data } = await axios.post('/api/beehiiv/subscribe', {
        email: values.email,
      });
      resetForm();
      setModalId(data.subscriber.id);
    } catch (error) {
      setSubmitting(false);
      const message = getErrorMessage(error);
      setError(message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <section className={classes} {...props}>
      <div className="relative mx-auto grid w-full grid-cols-1 items-center gap-y-3 py-8 md:container  sm:px-5 lg:static lg:grid-cols-2 lg:gap-y-12 lg:py-20 xl:py-20">
        <Typography
          variant="paragraph"
          color="white"
          className="flex-1 font-title text-2xl md:text-3xl"
        >
          {title}
        </Typography>

        <Formik initialValues={initialValue} onSubmit={handleSubmit}>
          {({ values, handleChange, isSubmitting, handleSubmit }) => (
            <form className="flex-2 lg:pl-8" onSubmit={handleSubmit}>
              <div className="m-0 w-auto rounded-xl border-8 border-white bg-white sm:relative sm:flex sm:items-center sm:justify-between sm:border-none sm:py-0.5 sm:pr-2.5">
                <div className="flex w-full items-center pl-0 sm:pl-2">
                  <div className="s pointer-events-none  pl-4 sm:mt-0 sm:pl-2">
                    <MailIcon />
                  </div>
                  <input
                    type="email"
                    required
                    aria-label="Email address"
                    placeholder={placeholderText}
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    className="peer relative mx-2 w-1/2 overflow-x-auto truncate rounded-xl border-none px-2 py-3 text-base placeholder:text-slate-400 focus:border-transparent focus:outline-none focus:ring-0 sm:w-full"
                  />
                </div>

                <Button
                  size="lg"
                  fullwidth
                  disabled={isSubmitting}
                  className="rounded-xl disabled:cursor-not-allowed disabled:opacity-50 sm:relative sm:z-10 sm:mx-0 sm:mb-0 sm:w-auto sm:flex-none md:my-2"
                >
                  {isSubmitting ? 'Submitting...' : buttonText}
                </Button>
              </div>
              {error && (
                <Typography
                  variant="paragraph"
                  className="mt-2 w-full text-sm text-red-300"
                >
                  {error}
                </Typography>
              )}
            </form>
          )}
        </Formik>

        {modalId && (
          <NewsletterModal open={!!modalId} onClose={() => setModalId(null)} />
        )}
      </div>
    </section>
  );
};
